<template>
  <div>
    <v-form>
      <p class="mb-5">Consider the reactants and conditions shown below:</p>
      <p class="mb-4">
        <v-img :src="imageHeaderName" style="max-width: 550px" />
      </p>

      <p class="mb-2">Which possible mechanism(s) can occur? (Select all that apply)</p>
      <p v-for="option in msOptions" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.input1" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-2 mt-2">
        Which mechanism is favored under these conditions? Justify your answer by discussing the
        properties of the reactants and solvent.
      </p>
      <s-textarea v-model="inputs.input2" outlined />

      <p class="mb-2">Which of the following would be the major product of this reaction?</p>
      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in mcOptions"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <v-img :src="option.img" style="max-width: 250px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemLC241PS9Q6',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: null,
        input3: null,
      },
      mcOptions: [
        {img: '/img/assignments/LC241/q6-mc-img1.png', value: 'a'},
        {img: '/img/assignments/LC241/q6-mc-img2.png', value: 'b'},
        {img: '/img/assignments/LC241/q6-mc-img3.png', value: 'c'},
        {img: '/img/assignments/LC241/q6-mc-img4.png', value: 'd'},
        {img: '/img/assignments/LC241/q6-mc-img5.png', value: 'e'},
      ],
      msOptions: [
        {text: 'SN1', value: 'SN1'},
        {text: 'SN2', value: 'SN2'},
        {text: 'E1', value: 'E1'},
        {text: 'E2', value: 'E2'},
      ],
    };
  },
  computed: {
    imageHeaderName() {
      return '/img/assignments/LC241/q6-header.png';
    },
  },
};
</script>
